import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { TranslationModeEnum } from '../../../commons/enums/translation-mode-enum';

@Component({
  selector: 'app-what',
  templateUrl: './what.component.html',
  styleUrl: './what.component.scss'
})
export class WhatComponent implements OnInit, AfterViewInit {
  itineraries: any[] = [
    {
      iconSrc: '/assets/images/what/icon/1.svg',
      imgSrc: '/assets/images/what/bg/1.webp',
      number: '1',
      sessions: '5',
      type: 'home_Data18',
      color: '#AFF4C6',
      border: '#8CC39E',
    },
    {
      iconSrc: '/assets/images/what/icon/2.svg',
      imgSrc: '/assets/images/what/bg/2.webp',
      number: '2',
      sessions: '3',
      type: 'home_Data19',
      color: '#E4CCFF',
      border: '#B6A3CC',
    },
    {
      iconSrc: '/assets/images/what/icon/3.svg',
      imgSrc: '/assets/images/what/bg/3.webp',
      number: '3',
      sessions: '5',
      type: 'home_Data20',
      color: '#BDE3FF',
      border: '#97B6CC',
    },
    {
      iconSrc: '/assets/images/what/icon/4.svg',
      imgSrc: '/assets/images/what/bg/4.webp',
      number: '4',
      sessions: '3',
      type: 'home_Data21',
      color: '#FFE8A3',
      border: '#CCBA82',
    },
    {
      iconSrc: '/assets/images/what/icon/5.svg',
      imgSrc: '/assets/images/what/bg/5.webp',
      number: '5',
      sessions: '5',
      type: 'home_Data22',
      color: '#FFC7C2',
      border: '#CC9F9B',
    },
    {
      iconSrc: '/assets/images/what/icon/6.svg',
      imgSrc: '/assets/images/what/bg/6.webp',
      number: '6',
      sessions: '2',
      type: 'home_Data23',
      color: '#E4CCFF',
      border: '#B6A3CC',
    },
    {
      iconSrc: '/assets/images/what/icon/7.svg',
      imgSrc: '/assets/images/what/bg/7.webp',
      number: '7',
      sessions: '3',
      type: 'home_Data24',
      color: '#FFE8A3',
      border: '#CCBA82',
    },
    {
      iconSrc: '/assets/images/what/icon/8.svg',
      imgSrc: '/assets/images/what/bg/8.webp',
      number: '8',
      sessions: '4',
      type: 'home_Data25',
      color: '#FFC7C2',
      border: '#CC9F9B',
    },
    {
      iconSrc: '/assets/images/what/icon/9.svg',
      imgSrc: '/assets/images/what/bg/9.webp',
      number: '9',
      sessions: '3',
      type: 'home_Data26',
      color: '#AFF4C6',
      border: '#8CC39E',
    },
    {
      iconSrc: '/assets/images/what/icon/10.svg',
      imgSrc: '/assets/images/what/bg/10.webp',
      number: '10',
      sessions: '2',
      type: 'home_Data27',
      color: '#BDE3FF',
      border: '#97B6CC',
    }
  ];
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;
  scrollPosition = 0;
  itemWidth = 0;
  maxScrollPosition = 0;

  @ViewChild('itineraryContainer', { static: false }) itineraryContainer!: ElementRef;

  constructor() { }

  ngOnInit(): void {
    this.calculateDimensions();
  }

  ngAfterViewInit(): void {
    this.calculateDimensions();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.calculateDimensions();
    this.adjustScrollPosition();
  }

  /**
   * Calcula la posición máxima de desplazamiento.
   */
  calculateDimensions() {
    let gap = 30;
    const itineraryElement = this.itineraryContainer?.nativeElement?.querySelector('.itinerary');
    if (itineraryElement) {
      this.itemWidth = itineraryElement.offsetWidth + gap;
    }
    const containerWidth = this.itineraryContainer?.nativeElement?.offsetWidth;
    const totalItemsWidth = (this.itineraries?.length * this.itemWidth) - gap / 2;
    this.maxScrollPosition = Math.max(0, totalItemsWidth - containerWidth);
  }

  /**
   * Si el scroll actual está fuera de los límites, ajustarlo.
   */
  adjustScrollPosition() {
    if (this.scrollPosition > this.maxScrollPosition) {
      this.scrollPosition = this.maxScrollPosition;
    } else if (this.scrollPosition < 0) {
      this.scrollPosition = 0;
    }
  }

  /**
   * Avanza un item hasta el máximo desplazamiento permitido.
   */
  next() {
    this.scrollPosition = Math.min(this.scrollPosition + this.itemWidth, this.maxScrollPosition);
  }

  /**
   * Retrocede un item hasta el inicio.
   */
  prev() {
    this.scrollPosition = Math.max(this.scrollPosition - this.itemWidth, 0);
  }
}
