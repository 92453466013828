<nav class="navbar">
  <a href="mailto:info@smartmakings.com">
    <img class="email" src="/assets/images/header/email.svg" alt="Email">
  </a>
  <a href="/" class="home">
    <img class="logo" src="/assets/images/header/logo.svg" alt="Logo">
  </a>
  <a href="tel:+34682605166">
    <img class="phone" src="/assets/images/header/phone.svg" alt="Phone">
  </a>
  <div class="btn-container">
    <a [href]="appUrl">
      <button class="btn teacher" [innerHTML]="'header_Data1' |i18n"></button>
    </a>
    <a [href]="playUrl">
      <button class="btn student" [innerHTML]="'header_Data2' |i18n"></button>
    </a>
  </div>
</nav>
