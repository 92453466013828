import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RestClientService } from "./rest-client.service";

@Injectable({
  providedIn: "root",
})
export class ContactRemoteService {
  private URL = "/contact";

  constructor(
    private restClientService: RestClientService
  ) { }

  sendContactForm(request): Observable<any> {
    return this.restClientService.post(`${this.URL}/form`, request);
  }
}
