import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  playUrl: string;
  appUrl: string;

  constructor() {
    this.appUrl = environment.appUrl;
    this.playUrl = environment.playUrl;
  }
}
