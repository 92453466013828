import { Component, OnInit } from '@angular/core';
import { TranslationModeEnum } from '../../enums/translation-mode-enum';
import { Location } from '@angular/common';
import { UpdateMetaService } from '../../services/meta-service.service';

@Component({
  selector: 'app-tos-cookies',
  templateUrl: './tos-cookies.component.html',
  styleUrl: './tos-cookies.component.scss'
})
export class TosCookiesComponent implements OnInit {
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;

  constructor(
    private location: Location,
    private updateMetaService: UpdateMetaService
  ) { }

  ngOnInit(): void {
    this.updateMetaService.updateMetaTags('Cookies');
  }

  /**
   * Vuelve a la última ubicación.
   */
  goBack(): void {
    this.location.back();
  }
}
