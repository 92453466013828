import { Component } from '@angular/core';

@Component({
  selector: 'app-how',
  templateUrl: './how.component.html',
  styleUrl: './how.component.scss'
})
export class HowComponent {

}
