import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { I18nService } from '../../../commons/services/i18n.service';
import { TranslationModeEnum } from '../../../commons/enums/translation-mode-enum';
import { WindowService } from '../../../commons/services/window.service';
import { SchoolContactFormComponent } from '../dialogs/school-contact-form/school-contact-form.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-ally',
  templateUrl: './ally.component.html',
  styleUrl: './ally.component.scss'
})
export class AllyComponent implements OnInit, AfterViewInit {
  text = 'home_Data40';
  lettersArray: string[] = [];
  translationModes: typeof TranslationModeEnum = TranslationModeEnum
    ;
  @ViewChild('animatedButton') animatedButton!: ElementRef;

  constructor(
    private i18nService: I18nService,
    private windowService: WindowService,
    private renderer: Renderer2,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    const rawText = this.i18nService.getText(this.text, this.translationModes.REMOVE_TAGS);
    this.lettersArray = rawText.split('').map(char => (char === ' ' ? '&nbsp;' : char));
  }

  ngAfterViewInit(): void {
    if (this.windowService.isBrowser) {
      const button = this.animatedButton.nativeElement;
      const spans = button.querySelectorAll('span');
      spans.forEach((span: HTMLElement, index: number) => {
        // const delay = index * 0.01;
        const delay = 0;
        this.renderer.setStyle(span, 'animation-delay', `${delay}s`);
      });
    }
  }

  openSchoolContact(): void {
    this.dialog.open(SchoolContactFormComponent, {
      panelClass: 'school-contact-form',
      backdropClass: 'school-contact-backdrop',
      disableClose: true,
      maxWidth: 'none',
      scrollStrategy: new NoopScrollStrategy(),
    });
  }
}
