import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslationModeEnum } from '../../../../commons/enums/translation-mode-enum';
import { ContactRemoteService } from '../../../../commons/services/api/contact-remote.service';

@Component({
  selector: 'app-school-contact-form',
  templateUrl: './school-contact-form.component.html',
  styleUrl: './school-contact-form.component.scss'
})
export class SchoolContactFormComponent implements OnInit {
  schoolContactForm: FormGroup;
  submitted: boolean = false;
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;
  formSendSuccessfully: boolean = false;
  formSendError: boolean = false;

  constructor(
    private dialog: MatDialogRef<SchoolContactFormComponent>,
    private fb: FormBuilder,
    private contactService: ContactRemoteService
  ) { }

  ngOnInit(): void {
    this.createForm();
    setTimeout(() => {
      this.dialog.addPanelClass('animate');
    }, 100);
  }

  /**
   * Crea el formulario.
   */
  createForm(): void {
    this.schoolContactForm = this.fb.group({
      schoolName: ['', Validators.required],
      responsiblePerson: ['', Validators.required],
      position: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      // phone: ['', [Validators.required, Validators.pattern('^[0-9]{9,15}$')]],
      terms: [false, Validators.requiredTrue],
      newsletter: [false],
    });
  }

  /**
   * Método para manejar el envío del formulario.
   */
  onSubmit(): void {
    if (this.formSendSuccessfully) {
      this.close();
      return;
    }
    this.submitted = true;
    if (this.schoolContactForm.valid) {
      const formData = new FormData();
      const formValues = this.schoolContactForm.value;
      if (formValues.schoolName) {
        formData.append("school_name", formValues.schoolName);
      }
      if (formValues.responsiblePerson) {
        formData.append("contact_name", formValues.responsiblePerson);
      }
      if (formValues.position) {
        formData.append("contact_role", formValues.position);
      }
      if (formValues.email) {
        formData.append("email", formValues.email);
      }

      formData.append("newsletter", formValues.newsletter ? '1' : '0');

      this.contactService.sendContactForm(formData).subscribe({
        next: (success) => {
          this.formSendSuccessfully = true;
        },
        error: (error) => {
          console.log(error);
          this.formSendError = true;
        },
      });
    }
  }

  /**
   * Vuelve al formulario.
   */
  retry(): void {
    this.formSendError = false;
  }

  /**
   * Cierra el dialog.
   */
  close(): void {
    this.dialog.removePanelClass('animate');
    setTimeout(() => {
      this.dialog.close();
    }, 500);
  }
}
