import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { WindowService } from '../../../commons/services/window.service';
import { ResponsiveService } from '../../../commons/services/responsive.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrl: './partners.component.scss'
})
export class PartnersComponent implements OnInit, AfterViewInit {
  images: string[] = [];
  allImages = [
    '/assets/images/partners/images/1.svg',
    '/assets/images/partners/images/2.svg',
    '/assets/images/partners/images/3.svg',
    '/assets/images/partners/images/4.svg',
    '/assets/images/partners/images/5.svg',
    '/assets/images/partners/images/6.svg',
    '/assets/images/partners/images/7.svg',
    '/assets/images/partners/images/8.svg',
    '/assets/images/partners/images/9.svg',
    '/assets/images/partners/images/10.svg',
    '/assets/images/partners/images/11.svg',
    '/assets/images/partners/images/12.svg',
    '/assets/images/partners/images/13.svg',
  ];
  repeatCount: number = 2;
  private position = 0;
  private totalWidth = 0;
  offset: number = 200;
  @ViewChild('itemsContainer') itemsContainer!: ElementRef;

  constructor(
    private windowService: WindowService,
    private responsiveService: ResponsiveService
  ) { }

  ngOnInit(): void {
    this.images = this.shuffleArray(this.allImages);
    // this.populateItems();
  }

  ngAfterViewInit() {
    this.startSlider(1);
  }

  /**
   * Añade los items del scroll
  */
  populateItems() {
    for (let i = 0; i < this.repeatCount; i++) {
      this.images.push(...this.images);
    }
  }

  /**
   * Función para aleatorizar el orden de las imágenes
   * @param array
   * @returns
   */
  shuffleArray(array: string[]): string[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  /**
   * Avanza
   */
  next() {
    this.startSlider(1); // Mover hacia adelante (inicializa con 0.5)
  }

  /**
   * Retrocede
   */
  prev() {
    this.startSlider(-1); // Mover hacia atrás (inicializa con -0.5)
  }

  /**
   * Mueve el slider en la dirección indicada
   * @param direction Dirección en la que se mueve el slider
   */
  startSlider(direction: number) {
    if (this.windowService.isBrowser) {
      const container = this.itemsContainer.nativeElement as HTMLElement;
      let totalWidth = 0;
      const images = container.querySelectorAll('img');
      const loadedImages = Array.from(images) as HTMLImageElement[];
      let loadedCount = 0;

      loadedImages.forEach((img) => {
        img.onload = () => {
          loadedCount++;
          if (loadedCount === loadedImages.length) {
            totalWidth = 0;
            loadedImages.forEach((img) => {
              totalWidth += img.offsetWidth;
            });
            this.totalWidth = totalWidth;
            this.animateSlider(container, totalWidth, direction);
          }
        };
      });

      if (loadedImages.length === 0 || loadedImages.every((img) => img.complete)) {
        totalWidth = 0;
        loadedImages.forEach((img) => {
          totalWidth += img.offsetWidth;
        });
        this.totalWidth = totalWidth;
        this.animateSlider(container, totalWidth, direction);
      }
    }
  }

  /**
   * Función para animar el slider
   */
  animateSlider(container: HTMLElement, totalWidth: number, direction: number) {
    let position = this.position;

    if (this.responsiveService.isPC()) {
      this.offset = -700;
    } else if (this.responsiveService.isLandscape()) {
      this.offset = -300;
    } else if (this.responsiveService.isTablet()) {
      this.offset = -100;
    } else {
      this.offset = 200;
    }

    const animate = () => {
      position += direction;
      if (Math.abs(position) >= totalWidth + this.offset) {
        position = 0;
      } else if (position < 0) {
        position = totalWidth + this.offset;
      }
      container.style.transform = `translateX(-${position}px)`;
      this.position = position;
      requestAnimationFrame(animate);
    };

    requestAnimationFrame(animate);
  }
}

// import { Component, OnInit, ViewChild } from '@angular/core';
// import { NgImageSliderComponent } from 'ng-image-slider';

// @Component({
//   selector: 'app-partners',
//   templateUrl: './partners.component.html',
//   styleUrl: './partners.component.scss'
// })
// export class PartnersComponent implements OnInit {
//   showImages: object[] = [];
//   allImages: object[] = [
//     {
//       "image": "/assets/images/partners/images/1.svg",
//       "thumbImage": "/assets/images/partners/images/1.svg"
//     },
//     {
//       image: '/assets/images/partners/images/2.svg',
//       thumbImage: "/assets/images/partners/images/2.svg"
//     },
//     {
//       image: '/assets/images/partners/images/3.svg',
//       thumbImage: '/assets/images/partners/images/3.svg'
//     },
//     {
//       image: '/assets/images/partners/images/4.svg',
//       thumbImage: '/assets/images/partners/images/4.svg'
//     },
//     {
//       image: '/assets/images/partners/images/5.svg',
//       thumbImage: '/assets/images/partners/images/5.svg'
//     },
//     {
//       image: '/assets/images/partners/images/6.svg',
//       thumbImage: '/assets/images/partners/images/6.svg'
//     },
//     {
//       image: '/assets/images/partners/images/7.svg',
//       thumbImage: '/assets/images/partners/images/7.svg'
//     },
//     {
//       image: '/assets/images/partners/images/8.svg',
//       thumbImage: '/assets/images/partners/images/8.svg'
//     },
//     {
//       image: '/assets/images/partners/images/9.svg',
//       thumbImage: '/assets/images/partners/images/9.svg'
//     },
//     {
//       image: '/assets/images/partners/images/10.svg',
//       thumbImage: '/assets/images/partners/images/10.svg'
//     },
//     {
//       image: '/assets/images/partners/images/11.svg',
//       thumbImage: '/assets/images/partners/images/11.svg'
//     },
//     {
//       image: '/assets/images/partners/images/12.svg',
//       thumbImage: '/assets/images/partners/images/12.svg'
//     },
//     {
//       image: '/assets/images/partners/images/13.svg',
//       thumbImage: '/assets/images/partners/images/13.svg'
//     }
//   ];

//   @ViewChild('nav') slider: NgImageSliderComponent;

//   constructor() { }

//   ngOnInit(): void {
//     this.showImages = this.shuffleArray(this.allImages);
//   }

//   /**
//    * Función para aleatorizar el orden de las imágenes
//    * @param array
//    * @returns
//    */
//   shuffleArray(array: object[]): object[] {
//     for (let i = array.length - 1; i > 0; i--) {
//       const j = Math.floor(Math.random() * (i + 1));
//       [array[i], array[j]] = [array[j], array[i]];
//     }
//     return array;
//   }

//   /**
//    * Avanza
//    */
//   next() {
//     this.slider.next();
//   }

//   /**
//    * Retrocede
//    */
//   prev() {
//     this.slider.prev();
//   }
// }

