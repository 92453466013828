import { Component, OnInit } from '@angular/core';
import { TranslationModeEnum } from '../../enums/translation-mode-enum';
import { Location } from '@angular/common';
import { UpdateMetaService } from '../../services/meta-service.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrl: './faqs.component.scss'
})
export class FaqsComponent implements OnInit {
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;
  isAnswerShown: boolean[] = [];
  faqList: any[] = [];

  constructor(
    private location: Location,
    private updateMetaService: UpdateMetaService
  ) { }

  ngOnInit(): void {
    this.updateMetaService.updateMetaTags('Faqs');
    this.generateFaqs();
  }

  /**
   * genera automáticamente las 8 preguntas partiendo de faqs_Data3 para la primera question
   * y de faqs_Data4 para la primera answer.
   */
  generateFaqs(): void {
    for (let i = 3; i <= 18; i += 2) {
      this.faqList.push({
        question: `faqs_data${i}`,
        answer: `faqs_data${i + 1}`,
      });
    }
  }

  /**
   * Vuelve a la última ubicación.
   */
  goBack(): void {
    this.location.back();
  }

  /**
   * Muestra la respuesta.
   * @param index
   */
  showAnswer(index: number): void {
    this.isAnswerShown[index] = !this.isAnswerShown[index];
  }
}
