import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { I18nService } from '../i18n.service';
import { Injectable } from '@angular/core';
import { RestClientErrorHandlerService } from './rest-client-error-handler.service';
import { UserToken } from '../../models/user-token';
import { SecurityTokenStorage } from '../storage/security-token-storage';
import { StorageService } from '../storage/storage.service';
import { environment } from '../../../../environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class RestClientService {
  constructor(
    private httpClient: HttpClient,
    private i18nService: I18nService,
    private restClientErrorHandler: RestClientErrorHandlerService,
    private securityTokenStorage: SecurityTokenStorage<UserToken>,
    private storageService: StorageService
  ) { }

  public get<T>(url: string, params?: any): Observable<T> {
    const completeUrl = this.getCompleteUrl(url);
    return this.httpClient
      .get<T>(
        completeUrl,
        this.getRequestOptions(params, url.includes('auth-user'))
      )
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  public post<T>(url: string, data: any): Observable<T> {
    const completeUrl = this.getCompleteUrl(url);
    return this.httpClient
      .post<T>(completeUrl, data, this.getRequestOptions({}))
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  public put<T>(url: string, data: any): Observable<T> {
    const completeUrl = this.getCompleteUrl(url);
    return this.httpClient
      .put<T>(completeUrl, data, this.getRequestOptions({}))
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  public delete<T>(url: string, params?: any): Observable<T> {
    const completeUrl = this.getCompleteUrl(url);
    return this.httpClient
      .delete<T>(completeUrl, this.getRequestOptions(params))
      .pipe(
        catchError((error) => this.restClientErrorHandler.handleError(error))
      );
  }

  private getCompleteUrl(url: string): string {
    return url.length > 0 && url[0] === '/'
      ? `${environment.apiUrl}${url}`
      : `${environment.apiUrl}/${url}`;
  }

  private getRequestOptions(
    params: any,
    forceToken: boolean = false
  ) {
    return {
      headers: this.getHttpHeaders(forceToken),
      params: this.getHttpParams(params),
    };
  }

  public getHttpHeaders(forceToken: boolean = false): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.set(
      'Accept-Language',
      this.i18nService.getCurrentLanguage().httpHeader
    );
    if (forceToken) {
      const acceptedLogin = this.securityTokenStorage.getAcceptedLogin();
      if (acceptedLogin && acceptedLogin.token) {
        httpHeaders = httpHeaders.set('Authorization', acceptedLogin.token);
      }
    }
    return httpHeaders;
  }

  public getHttpHeadersExternal(httpHeaders: HttpHeaders): HttpHeaders {
    httpHeaders = httpHeaders || new HttpHeaders();
    httpHeaders = httpHeaders.set(
      'Accept-Language',
      this.i18nService.getCurrentLanguage().httpHeader
    );
    const acceptedLogin = this.storageService.get('externalToken', '');
    if (acceptedLogin !== '') {
      httpHeaders = httpHeaders.set('Authorization', 'Bearer ' + acceptedLogin);
    }
    return httpHeaders;
  }

  private getHttpParams(params: any): HttpParams {
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params).forEach((key) => {
        httpParams = httpParams.set(key, params[key]);
      });
    }
    return httpParams;
  }
}
