import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslationModeEnum } from '../../enums/translation-mode-enum';
import { UpdateMetaService } from '../../services/meta-service.service';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrl: './legal.component.scss'
})
export class LegalComponent implements OnInit {
  translationModes: typeof TranslationModeEnum = TranslationModeEnum;

  constructor(
    private location: Location,
    private updateMetaService: UpdateMetaService
  ) { }

  ngOnInit(): void {
    this.updateMetaService.updateMetaTags('Legal');
  }

  /**
   * Vuelve a la última ubicación.
   */
  goBack(): void {
    this.location.back();
  }
}
