import { Directive, ElementRef, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { WindowService } from './commons/services/window.service';

@Directive({
  selector: '[appLazyLoad]',
  standalone: false
})
export class LazyLoadDirective implements OnInit, OnDestroy {
  @Output() inViewport = new EventEmitter<void>();

  private scrollHandler: () => void;

  constructor(
    private el: ElementRef,
    private windowService: WindowService
  ) {
  }

  @HostListener('window:scroll')
  onScroll(): void {
    if (this.windowService.isBrowser) {
      const element = this.el.nativeElement;
      const rect = element.getBoundingClientRect();
      const windowHeight = (window.innerHeight || document.documentElement.clientHeight);

      if (rect.top <= windowHeight && rect.bottom >= 0) {
        this.inViewport.emit();
        this.removeListeners();
      }
    }
  }

  ngOnInit(): void {
    if (this.windowService.isBrowser) {
      this.scrollHandler = this.onScroll.bind(this);
      window.addEventListener('scroll', this.scrollHandler);
      window.addEventListener('resize', this.scrollHandler);
      this.checkVisibility();
    }
  }

  private checkVisibility(): void {
    this.onScroll();
  }

  private removeListeners(): void {
    if (this.windowService.isBrowser) {
      window.removeEventListener('scroll', this.scrollHandler);
      window.removeEventListener('resize', this.scrollHandler);
    }
  }

  /**
   * Clean up the event listener when the directive is destroyed
   */
  ngOnDestroy(): void {
    this.removeListeners();
  }
}
