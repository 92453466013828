import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { BaseStorageService } from './base-storage.service';
import { UserToken } from '../../models/user-token';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root',
})
export class SecurityTokenStorage<T> extends BaseStorageService<T> {
  private OnSessionExpired = new Subject<UserToken>();

  constructor(storageService: StorageService) {
    super(storageService);
  }

  public onSessionExpired(): Observable<any> {
    return this.OnSessionExpired;
  }

  protected getStorageKey(): string {
    return 'smartmakings';
  }

  public getAcceptedLogin(): T {
    return super.getObjectValue();
  }
}
