import { Component, HostListener, OnInit } from '@angular/core';
import { CookiesConfigEnum } from '../../../enums/cookies-config-enum';
import { cookiesLists } from '../../../enums/cookies-list';
import { MatDialogRef } from '@angular/material/dialog';
import { ResponsiveService } from '../../../services/responsive.service';
import { WindowService } from '../../../services/window.service';

interface CookieType {
  type: CookiesConfigEnum;
  label: string;
}

@Component({
  selector: 'app-optional-nc-dialog',
  templateUrl: './optional-nc-dialog.component.html',
  styleUrl: './optional-nc-dialog.component.scss'
})
export class OptionalNcDialogComponent implements OnInit {

  cookiesConfigEnum: typeof CookiesConfigEnum;
  cookiesLists: typeof cookiesLists;
  currentCookies: CookiesConfigEnum;
  isTablet: boolean = false;
  showDetails: boolean = false;
  analysisToggle: boolean = true;
  functionalityToggle: boolean = true;
  marketingToggle: boolean = true;
  fraudToggle: boolean = true;
  securityToggle: boolean = true;
  cookieTypes: CookieType[] = [
    { type: CookiesConfigEnum.ESSENTIAL, label: 'cookies_config_Data6' },
    { type: CookiesConfigEnum.ANALYSIS, label: 'cookies_config_Data7' },
    { type: CookiesConfigEnum.FUNCTIONALITY, label: 'cookies_config_Data8' },
    { type: CookiesConfigEnum.MARKETING, label: 'cookies_config_Data9' },
    { type: CookiesConfigEnum.FRAUD, label: 'cookies_config_Data10' },
    { type: CookiesConfigEnum.SECURITY, label: 'cookies_config_Data13' }
  ];
  scrollPosition = 0;

  constructor(
    private matDialogRef: MatDialogRef<OptionalNcDialogComponent>,
    private responsiveService: ResponsiveService,
    private windowService: WindowService
  ) {
    this.cookiesConfigEnum = CookiesConfigEnum;
    this.cookiesLists = cookiesLists;
    this.currentCookies = CookiesConfigEnum.ESSENTIAL;
  }

  ngOnInit(): void {
    this.isTablet = this.responsiveService.isTablet();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.isTablet = this.responsiveService.isTablet();
  }

  /**
   * Establece las cookies
   * @param cookiesType
   */
  setCurrentCookies(cookiesType: CookiesConfigEnum): void {
    this.currentCookies = cookiesType;
    this.showDetails = false;
  }

  /**
   * Muestra los detalles de las cookies
   */
  showCookiesDetails(): void {
    this.showDetails = !this.showDetails;
  }

  /**
   * Acepta las cookies seleccionadas
   * @param response
   */
  acceptCookies(response: CookiesConfigEnum): void {
    if (response === CookiesConfigEnum.ALL) {
      this.updateToggles(true);
    } else if (response === CookiesConfigEnum.ESSENTIAL) {
      this.updateToggles(false);
    }

    const selectedToggles: { [key: string]: boolean } = {
      ESSENTIAL: true,
      ANALYSIS: this.analysisToggle,
      FUNCTIONALITY: this.functionalityToggle,
      MARKETING: this.marketingToggle,
      FRAUD: this.fraudToggle,
      SECURITY: this.securityToggle
    };
    if (this.windowService.isBrowser) {
      localStorage.setItem('selectedCookiesSM', JSON.stringify(selectedToggles));
    }
    this.matDialogRef.close();
  }

  /**
   * Actualiza los toggle de las cookies aceptadas
   * @param value
   */
  private updateToggles(value: boolean): void {
    this.analysisToggle = value;
    this.functionalityToggle = value;
    this.marketingToggle = value;
    this.fraudToggle = value;
    this.securityToggle = value;
  }
}
